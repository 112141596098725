@import 'src/assets/scss/_core.scss';

.formHeader {
  width: 100%;
  max-width: rem(480);
  margin: 0 auto;
  padding: rem(40) rem(24) rem(36);
  display: flex;
  flex-direction: column;
  gap: rem(12);
  position: relative;
  .gymdoneLogo {
    font-size: rem(200);
    position: absolute;
    display: block;
    top: rem(-50);
    right: rem(-50);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(270deg, c(text, 0.05) 12.92%, c(text, 0) 96.32%);
  }
  > .title {
    margin: 0 auto;
    font-weight: 400;
    font-size: rem(16);
  }
  .progressAndBack {
    height: rem(28);
    position: relative;
    display: flex;
    align-items: center;
    &.show {
      .backButton {
        opacity: 1;
        pointer-events: visible;
        transform: translateX(0);
      }
      .progressWrapper {
        margin-left: rem(42);
      }
    }
    .backButton {
      width: rem(32);
      height: rem(28);
      background: c(main);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(12);
      border-radius: rem(6);
      flex-shrink: 0;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      cursor: pointer;
      transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateX(-80%);
      opacity: 0;
      @include hover {
        background: c(main, 0.8);
      }
    }
    .progressWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
      position: relative;
      .lineWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        margin: 0 rem(4);
        left: 0;
        width: 100%;
        height: rem(4);
        border-radius: rem(4);
        background: c(main);
        position: relative;
        .line {
          background: c(text);
          border-radius: rem(4);
          width: 0;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: width 400ms;
        }
      }
      .dotsWrapper {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .dot {
          width: rem(16);
          height: rem(16);
          border-radius: 50%;
          background: c(main);
          position: relative;
          z-index: 1;
          transition: background 400ms;
          box-shadow: 0 0 0 rem(1) c(bg);
          &.done {
            background: c(text);
          }
        }
      }
    }
  }
}
