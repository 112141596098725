@import "core.scss";
@import "reset.scss";
@import "../icons/style.css";

:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
  --font: "Poppins", sans-serif;
  --gradient: linear-gradient(
    223.26deg,
    #{c(main, 0.1)} 19.7%,
    #{c(main, 0.4)} 64.97%
  );
  --gradient-primary: linear-gradient(
    66deg,
    #{c(main, 0.2)} 0%,
    #{c(main, 0.05)} 45%,
    #{c(primary, 0.2)} 48%,
    #{c(primary, 0.02)} 100%
  );
}

html {
  font: 10px / 1.2 var(--font);
  overflow-x: hidden;
  background: c(bg);
  @include small-desktop {
    font-size: 9.5px;
  }
  @include mobile {
    font-size: 9px;
  }
}

body {
  font-size: rem(16);
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  color: c(text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  display: flex;
  &.male {
    --primary: var(--male);
    --primary-rgb: var(--male-rgb);
    --lightPrimary: var(--lightMale);
    --lightPrimary-rgb: var(--lightMale-rgb);
    --gradient-primary: linear-gradient(
      66deg,
      #{c(main, 0.2)} 0%,
      #{c(main, 0.05)} 45%,
      #{c(male, 0.3)} 48%,
      #{c(male, 0.02)} 100%
    );
  }
  &.female {
    --primary: var(--female);
    --primary-rgb: var(--female-rgb);
    --lightPrimary: var(--lightFemale);
    --lightPrimary-rgb: var(--lightFemale-rgb);
    --gradient-primary: linear-gradient(
      66deg,
      #{c(main, 0.2)} 0%,
      #{c(main, 0.05)} 45%,
      #{c(female, 0.3)} 48%,
      #{c(female, 0.02)} 100%
    );
  }
}
@keyframes page-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
