@import "src/assets/scss/_core.scss";

@keyframes indicatorFade {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(-50%);
  }
}

.tabSwitcher {
  display: flex;
  gap: rem(10);
  justify-content: center;
  margin-bottom: rem(18);
  flex-wrap: wrap;
  > .item {
    padding: rem(8) rem(14);
    background: c(main);
    border-radius: rem(16);
    font-size: rem(14);
    color: sc(main, 0.8);
    cursor: pointer;
    &.active {
      background: c(primary);
      color: sc(primary);
      cursor: default;
    }
  }
}
.hint {
  margin-top: rem(20);
  padding: rem(16);
  border-radius: rem(16);
  background: c(primary, 0.14);
  color: c(text);
  &.error {
    background: c(danger, 0.14);
  }
  > p {
    font-size: rem(12);
  }
  .title {
    display: flex;
    gap: rem(6);
    align-items: center;
    > i {
      font-size: rem(20);
    }
  }
  .content {
    line-height: 1.4;
    margin-top: rem(8);
    font-weight: 300;
  }
}
.fatBlock {
  .imageBlock {
    border-radius: rem(16);
    background: c(main);
    margin-bottom: rem(28);
    aspect-ratio: 4/2.5;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.fitnessLevel {
  > h3 {
    font-weight: 300;
    font-size: rem(16);
  }
  .infoItem {
    text-align: center;
    margin-top: rem(10);
    text-transform: uppercase;
    margin-bottom: rem(20);
    padding-bottom: rem(20);
    border-bottom: rem(1) solid c(main);
    font-weight: 600;
  }
  .bmi-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: rem(56) 0 0;
    text-align: center;
    font-family: Arial, sans-serif;
  }

  .bmi-scale {
    display: flex;
    height: rem(10);
    border-radius: rem(4);
    overflow: hidden;
    &.unHeight {
      height: auto;
      margin-top: rem(8);
    }
  }

  .bmi-segment {
    flex: 1;
    transition: all 0.3s ease;
    opacity: 0.7;
  }
  .bmi-segment,
  .segment-name {
    flex: 1;
    transition: all 0.3s ease;
  }

  .segment-name {
    font-size: rem(12);
    opacity: 0.8;
  }

  .underweight {
    background-color: #ffc107 !important;
  }

  .normal {
    background-color: #4caf50 !important;
  }

  .overweight {
    background-color: #ff9800 !important;
  }

  .obese {
    background-color: #f44336 !important;
  }

  .bmi-line {
    position: absolute;
    transform: translateX(-50%);
    top: rem(-4);
    height: rem(18);
    width: rem(2);
    border-radius: rem(2);
    background: c(text);
    animation: indicatorFade 1s backwards;
  }

  .bmi-indicator {
    position: absolute;
    color: #fff;
    top: rem(-38);
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: rem(12);
    white-space: nowrap;
    animation: indicatorFade 1s backwards;
  }
}

.informationBlock {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  text-align: center;
  margin-top: rem(-40);
  &.withProgress {
    margin-top: rem(-10);
    .info {
      margin-top: rem(-80);
    }
    .subInfo {
      margin: rem(60) auto 0;
    }
  }
  .reviews {
    width: 100%;
    margin-top: rem(46);
    flex-shrink: 0;
    .singleReview {
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: pre-line;
      font-size: rem(14);
      line-height: 1.4;
      text-align: center;
      > img {
        margin-bottom: rem(20);
      }
    }
  }
  .imageBlock {
    margin: 0 auto;
    filter: grayscale(1);
  }
  .info {
    margin-top: rem(20);
    position: relative;
    .blur {
      position: absolute;
      top: -80%;
      z-index: -1;
      filter: blur(10px);
      opacity: 0.8;
      width: rem(300);
      left: 50%;
      transform: translateX(-50%);
    }
    .title {
      font-size: rem(30);
      font-weight: 600;
      max-width: rem(300);
      margin: 0 auto;
    }
    .description {
      margin-top: rem(30);
      font-size: rem(18);
      opacity: 0.8;
      line-height: 1.6;
    }
  }
  .subInfo {
    margin: rem(60) auto 0;
    display: flex;
    align-items: center;
    gap: rem(20);
    .centerText {
      .subTitle {
        font-weight: bold;
        font-size: rem(40);
      }
      .subDescription {
        text-transform: uppercase;
        opacity: 0.7;
        font-size: rem(16);
        margin-top: rem(12);
      }
    }
  }
}

.singleOption {
  &.equipment {
    .imageBlock {
      transform: none;
      aspect-ratio: 1/0.8;
      padding: rem(6);
      > img {
        border-radius: rem(10);
        border-width: rem(6) !important;
      }
    }
  }
}

.inputBlockWrapper .inputLabel {
  margin-top: rem(20);
  margin-bottom: rem(8);
  padding: 0 rem(12);
}
