@import "src/assets/scss/_core.scss";

.singleOption {
  background: c(gradient-primary);
  border: rem(1) solid c(main);
  border-radius: rem(8);
  padding: rem(20) rem(20) rem(16);
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  background-size: 238% auto;
  cursor: pointer;
  min-height: rem(100);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  overflow: hidden;
  &.withImage {
    .label,
    .description {
      max-width: 62%;
    }
  }
  .imageBlock {
    padding: rem(1);
    aspect-ratio: 1;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    object-position: center bottom;
    transform: scale(1.4);
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #fff;
      border: rem(4) solid sc(main, 0.1);
      transition: border 400ms;
    }
  }
  .checkBox {
    position: absolute;
    top: rem(6);
    right: rem(6);
  }
  &.selected {
    border: rem(1) solid c(primary, 0.2);
    background-position: right;
    .imageBlock img {
      border-color: c(lightPrimary);
    }
  }
  .label {
    font-size: rem(18);
    font-weight: 600;
    line-height: rem(30);
    margin-bottom: rem(1);
    max-width: 93%;
  }
  .description {
    font-size: rem(14);
    margin-top: rem(4);
    line-height: rem(20);
    opacity: 0.8;
    max-width: 93%;
  }
  & + .singleOption {
    margin-top: rem(8);
  }
}
