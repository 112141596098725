@import "src/assets/scss/_core.scss";

.checkBox {
  flex-shrink: 0;
  width: rem(30);
  height: rem(30);
  border-radius: rem(6);
  background: c(main);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(12);
  color: transparent;
  transition: all 200ms ease-in-out;
  &.selected {
    background: c(primary);
    color: sc(primary);
  }
  &.circle {
    border-radius: 50%;
  }
}
