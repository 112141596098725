@import "src/assets/scss/_core.scss";

.planPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: page-fade 1.2s backwards ease-in-out;
  max-width: rem(480);
  margin: 0 auto;
  gap: rem(20);
  overflow-y: auto;
  overflow-x: hidden;
  padding: rem(24) rem(24) calc(env(safe-area-inset-bottom) + #{rem(16)});
  &.center {
    justify-content: center;
  }
  .downloadInfo {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: rem(20) 0;
    > h1 {
      font-size: rem(24);
      line-height: 1.6;
      max-width: rem(350);
      margin: 0 auto;
    }
    > p {
      line-height: 1.6;
      opacity: 0.7;
      font-size: rem(14);
      margin-top: rem(18);
    }
    img {
      max-width: rem(200);
      margin-top: rem(30);
      border-radius: rem(8);
      margin-left: auto;
      margin-right: auto;
    }
  }
  .orPart {
    margin-top: rem(40);
    border-top: rem(1) solid c(main);
    padding-top: rem(40);
    > h2 {
    }
    > p {
      margin-top: rem(24);
      color: sc(main, 0.7);
      line-height: 1.4;
      > a {
        color: sc(main);
      }
      b {
        font-weight: normal;
        color: c(danger);
      }
    }
    > img {
      max-width: rem(300);
    }
  }
  .optionBlock {
    flex: 1;
  }
  .paymentInfo {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: rem(20);
    > i {
      font-size: rem(50);
      opacity: 0.6;
    }
    > .button {
      max-width: 100%;
    }
  }
  .beforeAfterBlock {
    background: c(gradient);
    border: rem(1) solid c(main);
    border-radius: rem(8);
    .header {
      display: flex;
      > p {
        width: 100%;
        flex: 1;
        text-align: center;
        padding: 0 rem(16);
        margin: rem(16) 0;
        font-weight: 600;
        font-size: rem(18);
        position: relative;
        line-height: 1.4;
      }
    }
    .imageBlock {
      display: flex;
      justify-content: space-between;
      height: rem(145);
      position: relative;
      background: c(bg);
      z-index: 0;
      > .body {
        flex: 1;
        object-fit: contain;
        height: 100%;
      }
    }
    .info {
      display: flex;
      > .sub {
        flex: 1;
        padding: rem(20) rem(24);
        &:has(hr) {
          padding: 0 rem(16);
        }
        .title {
          font-weight: 600;
          font-size: rem(16);
        }
        .detail {
          margin-top: rem(6);
          opacity: 0.7;
        }
        &:first-child {
          border-right: rem(2) solid c(main);
        }
      }
      .massDetail {
        display: flex;
        gap: rem(4);
        margin-top: rem(12);
        .line {
          width: rem(22);
          height: rem(4);
          background: c(main);
          border-radius: rem(4);
          &.active {
            background: c(tertiary);
          }
        }
      }
      hr {
        width: 100%;
        height: rem(1);
        background: c(main, 0.7);
      }
    }
  }
  .guaranteeBlock {
    font-size: rem(14);
    padding: 0 rem(16);
    color: c(text, 0.5);
    display: flex;
    gap: rem(6);
    align-items: center;
    line-height: 1;
    font-weight: 300;
    justify-content: center;
    i {
      font-size: rem(16);
      color: c(text);
    }
  }
  .reservedDiscount {
    display: flex;
    justify-content: space-between;
    background: c(main, 0.4);
    padding: rem(16);
    border: rem(1) solid c(main);
    border-radius: rem(8);
    margin-top: rem(8);
    align-items: center;
    .timerBlock {
      .title {
        font-size: rem(16);
        font-weight: 300;
        margin-bottom: rem(4);
        opacity: 0.7;
      }
      .timer {
        font-weight: 700;
        font-size: rem(18);
        > span {
          display: inline-flex;
        }
      }
    }
  }
  .aiBlock {
    margin-top: rem(8);
    h2 {
      font-size: rem(20);
      b {
        color: c(tertiary);
      }
    }
    .content {
      background: c(gradient);
      border: rem(1) solid c(main);
      border-radius: rem(8);
      margin-top: rem(16);
      padding: rem(12);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(10);
      position: relative;
      .aiBadge {
        position: absolute;
        background: c(tertiary);
        color: c(bg);
        padding: rem(8) rem(10);
        display: inline-flex;
        gap: rem(4);
        border-radius: rem(26);
        align-items: center;
        font-size: rem(12);
        line-height: 1;
        right: rem(-10);
        top: rem(-15);
        font-weight: 600;
      }
      .single {
        border-radius: rem(8);
        background: c(main, 0.3);
        padding: rem(12) rem(16);
        display: flex;
        gap: rem(14);
        align-items: center;
        > i {
          font-size: rem(22);
          opacity: 0.8;
          color: c(tertiary);
        }
        .title {
          font-size: rem(14);
          font-weight: 300;
          opacity: 0.7;
        }
        .value {
          font-size: rem(16);
          font-weight: 600;
          margin-top: rem(2);
        }
      }
    }
  }
  .promoBlock {
    margin-top: rem(8);
    h2 {
      font-size: rem(20);
      max-width: 80%;
      line-height: 1.4;
    }
    .content {
      border-radius: rem(8);
      background: c(tertiary, 0.1);
      border: rem(1) solid c(tertiary, 0.5);
      padding: rem(16) rem(12);
      margin-top: rem(16);
      display: flex;
      gap: rem(16);
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: rem(170);
        > p {
          font-size: rem(12);
          margin-bottom: rem(8);
          color: c(tertiary);
          font-weight: 600;
        }
      }
      .leftSide {
        flex: 1;
        .promo {
          width: 100%;
          background: c(bg);
          border-radius: rem(4);
          flex: 1;
          color: c(text);
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          line-height: 1;
          gap: rem(4);
          .percentBadge {
            background: c(tertiary);
            color: c(bg);
            font-size: rem(10);
            padding: rem(4) rem(8);
            line-height: 1;
            border-radius: rem(20);
            b {
              text-decoration: line-through wavy c(danger) rem(1);
              font-weight: normal;
            }
          }
          .line {
            margin: rem(4) 0 rem(2);
            font-size: rem(12);
            text-decoration: line-through wavy c(danger) rem(1);
            text-transform: uppercase;
            font-weight: 300;
          }
          .newPromo {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
      .rightSide {
        .timerBlock {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: c(text);
          flex: 1;
          height: 100%;
          font-weight: 700;
          .dots {
            display: none !important;
          }
          .timer {
            display: flex;
            gap: rem(8);
            > span {
              display: flex;
              flex-direction: column;
              font-size: rem(36);
              background: c(bg);
              border-radius: rem(4);
              padding: rem(12);
              min-width: rem(80);
              text-align: center;
              b {
                font-size: rem(12);
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
  .planOptions {
    display: flex;
    flex-direction: column;
    gap: rem(10);
    margin: rem(20) 0;
    .moneyBackInfo {
      text-align: center;
      color: c(text, 0.6);
      font-size: rem(12);
      padding: rem(6);
      text-decoration: underline;
      text-decoration-color: c(text, 0.4);
    }
    > .button {
      width: 100%;
      max-width: initial;
      margin-top: rem(10);
    }
    .singlePlan {
      display: flex;
      background: c(gradient-primary);
      border: rem(1) solid c(main);
      border-radius: rem(8);
      transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
      background-size: 238% auto;
      cursor: pointer;
      padding: rem(12) rem(12) rem(12) rem(16);
      align-items: center;
      gap: rem(16);
      &:not(.selected) {
        @include hover {
          background: c(main, 0.4);
        }
      }
      &.selected {
        background-position: right;
        pointer-events: none;
        border-color: c(primary);
        .priceBlock {
          color: c(bg);
          background: c(primary);
          .range {
            color: c(bg, 0.7);
          }
        }
      }
      .info {
        flex: 1;
        .popular {
          display: inline-flex;
          background: c(primary);
          color: sc(primary);
          font-size: rem(10);
          padding: rem(4) rem(8);
          line-height: 1;
          align-items: center;
          gap: rem(4);
          border-radius: rem(20);
          margin-bottom: rem(6);
        }
        .title {
          font-size: rem(18);
          text-transform: uppercase;
          font-weight: 600;
        }
        .priceDay {
          font-size: rem(12);
          margin-top: rem(4);
          opacity: 0.7;
        }
      }
      .prevPrice {
        color: c(text, 0.8);
        text-decoration: line-through solid red rem(1);
      }
      .priceBlock {
        padding: rem(8) rem(10);
        border-radius: rem(6);
        background: c(main);
        transition: background 400ms;
        text-align: center;
        min-width: rem(100);
        .price {
          font-weight: 700;
          font-size: rem(20);
          margin: rem(2) 0;
        }
        .range {
          font-size: rem(10);
          color: c(text, 0.7);
        }
      }
    }
    .subscriptionInfo {
      margin: rem(32) 0 0;
      background: c(main, 0.3);
      padding: rem(16) rem(12) rem(16) rem(32);
      border: rem(1) dashed c(main);
      border-radius: rem(8);
      display: flex;
      flex-direction: column;
      gap: rem(16);
      color: c(text, 0.7);
      font-size: rem(14);
      a {
        color: c(text);
      }
      li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
        line-height: 1.3;
        &::marker {
          color: c(text);
        }
      }
    }
  }
  .successPayment {
    text-align: center;
    svg {
      margin-top: rem(-20);
    }
    h1 {
      margin-top: rem(-40);
      font-size: rem(20);
      line-height: 1.4;
      margin-bottom: rem(12);
    }
    p {
      font-size: rem(14);
      line-height: 1.6;
      opacity: 0.7;
    }
  }
}

.appStoreRatings {
  padding: rem(32);
  text-align: center;
  background: c(gradient);
  border: rem(1) solid c(main);
  border-radius: rem(8);
  margin: rem(20) 0;

  .storeButtons {
    display: flex;
    justify-content: center;
    gap: rem(16);
    margin-bottom: rem(24);

    .storeButton {
      cursor: pointer;
      transition: transform 0.2s ease;
      background: c(main);
      padding: rem(12) rem(24);
      border-radius: rem(8);
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem(140);
      
      &:hover {
        transform: scale(1.05);
        background: c(main, 0.8);
      }
      
      .storeIcon {
        height: rem(32);
        width: rem(32);
        color: c(text);
        display: block;
      }
    }
  }

  .ratings {
    margin-bottom: rem(16);

    .stars {
      color: #FFD700;
      font-size: rem(20);
      
      .rating {
        color: c(text);
        font-weight: 600;
        margin-left: rem(8);
      }

      .outOf {
        color: c(text, 0.7);
        font-size: rem(14);
        margin-left: rem(4);
      }
    }
  }

  .stats {
    color: c(text, 0.7);
    font-size: rem(14);

    .separator {
      margin: 0 rem(16);
    }
  }
}

.moneyBackGuarantee {
  padding: rem(32) rem(24);
  background: c(gradient);
  border: rem(1) solid c(main);
  border-radius: rem(8);
  margin: rem(20) 0;
  position: relative;
  overflow: visible;
  min-height: rem(300);

  .stampContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(200);
    height: rem(200);
    opacity: 0.08;
    pointer-events: none;
    z-index: 0;

    .stamp {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    max-width: rem(500);
    margin: 0 auto;
    padding: rem(12);

    h2 {
      font-size: rem(20);
      font-weight: 600;
      margin-bottom: rem(16);
      color: c(text);
      text-align: center;
    }

    .description {
      font-size: rem(14);
      line-height: 1.5;
      color: c(text, 0.8);
      margin-bottom: rem(16);
      text-align: center;
    }

    .policy {
      font-size: rem(12);
      line-height: 1.5;
      color: c(text, 0.7);
      margin-bottom: rem(16);
      text-align: center;
      word-break: break-word;
      
      a {
        color: c(text);
        text-decoration: underline;
      }
    }

    .cta {
      font-size: rem(14);
      font-weight: 500;
      color: c(text);
      text-align: center;
      margin-top: rem(16);
    }
  }
}
