@import "src/assets/scss/_core.scss";

@keyframes button-fade {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.actionBlock {
  padding: rem(16);
  display: flex;
  z-index: 8;
  justify-content: center;
  margin: auto 0 0 0;
  animation: button-fade 400ms backwards;
}

.button {
  font-size: rem(16);
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: rem(300);
  width: 100%;
  padding: rem(14);
  background: c(text);
  border-radius: rem(16);
  line-height: rem(24);
  cursor: pointer;
  color: c(bg);
  transition: transform 400ms;
  &.isSmall {
    width: auto;
    font-size: rem(14);
    line-height: rem(20);
    padding: rem(8) rem(16);
    border-radius: rem(8);
  }
  &.isPrimary {
    background: c(primary);
    color: sc(primary);
    font-weight: normal;
  }
  @include hover {
    transform: scale(1.05);
  }
}
