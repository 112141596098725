@font-face {
  font-family: 'gymdone-icons';
  src:
    url('fonts/gymdone-icons.woff2?94ilnk') format('woff2'),
    url('fonts/gymdone-icons.ttf?94ilnk') format('truetype'),
    url('fonts/gymdone-icons.woff?94ilnk') format('woff'),
    url('fonts/gymdone-icons.svg?94ilnk#gymdone-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gymdone-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-muscle:before {
  content: "\e913";
}
.icon-hand-fist:before {
  content: "\e911";
}
.icon-child:before {
  content: "\e912";
}
.icon-hot:before {
  content: "\e910";
}
.icon-stars:before {
  content: "\e908";
}
.icon-duration:before {
  content: "\e909";
}
.icon-wight:before {
  content: "\e90a";
}
.icon-goal:before {
  content: "\e90b";
}
.icon-level3:before {
  content: "\e90c";
}
.icon-level2:before {
  content: "\e90d";
}
.icon-level1:before {
  content: "\e90e";
}
.icon-price-background:before {
  content: "\e90f";
}
.icon-square-exclamation:before {
  content: "\e907";
}
.icon-girl:before {
  content: "\e905";
}
.icon-boy:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e904";
}
.icon-icon:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-logo:before {
  content: "\e903";
}
