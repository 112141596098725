@use "sass:math";
@use "sass:color";

$colors: (
  primary: #096c66,
  lightPrimary: #6e959e,
  secondary: #e78b00,
  tertiary: #85ac32,
  primary-sc: #ffffff,
  main: #dadada,
  main-sc: #282a32,
  border: #282a32,
  bg: #ffffff,
  text: #000000,
  danger: #ec2247,
  male: #143d89,
  lightMale: #c4cfe6,
  female: #a31569,
  lightFemale: #daafca,
);

@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  }
  @return rgba(var(--#{$color}-rgb), $opacity);
}

@mixin placeholder {
  $prefixList: "::-webkit-input", ":-moz", "::-moz", ":-ms-input";
  @each $prefix in $prefixList {
    &#{$prefix}-placeholder {
      @content;
      opacity: 1;
    }
  }
}

@function rem($size) {
  $remSize: math.div($size, 10);
  @if $size == 1 {
    @return #{$size}px;
  }
  @return #{$remSize}rem;
}

@function sc($color, $opacity: 1) {
  @if ($color == b) {
    $color: background;
  }
  @if $opacity == 1 {
    @return var(--#{$color}-sc);
  } @else {
    @return rgba(var(--#{$color}-sc-rgb), $opacity);
  }
}
@each $name, $color in $colors {
  $colors: map-merge(
    $colors,
    (
      #{$name + "-rgb"}: #{color.channel($color, "red", $space: rgb),
      color.channel($color, "green", $space: rgb),
      color.channel($color, "blue", $space: rgb)}
    )
  );
}
@mixin scroll-y($scroll: auto) {
  $border-radius: 0;
  $width: 6px;
  $scroll-main-color: #{c(text, 0.1)};
  $scroll-main-thumb-color: #{c(text, 0.2)};

  overflow-y: $scroll;
  overflow-x: hidden;
  scrollbar-track-color: $scroll-main-color;
  scrollbar-shadow-color: $scroll-main-color;
  scrollbar-base-color: $scroll-main-thumb-color;
  scrollbar-arrow-color: $scroll-main-thumb-color;
  &::-webkit-scrollbar {
    width: $width;
    background: $scroll-main-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $scroll-main-thumb-color;
  }
}
@mixin desktop {
  @media screen and (min-width: 981px) {
    @content;
  }
}
@mixin small-desktop {
  @media screen and (max-width: 980px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 450px) {
    @content;
  }
}
@mixin hover {
  @media all and (hover: hover) {
    &:hover {
      @content;
    }
  }
  @media not all and (hover: hover) {
    &:active {
      @content;
    }
  }
}
