@import "src/assets/scss/_core.scss";

.startWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  animation: page-fade 1.2s backwards ease-in-out;
  .headerImageWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    transition: height 400ms;
    @include small-desktop {
      height: 60%;
    }
    img {
      z-index: 0;
      height: 90%;
      width: 100%;
      object-fit: cover;
      object-position: top center;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        c(bg) 0%,
        c(bg) 43%,
        c(bg, 0.7) 52%,
        c(bg, 0) 65%
      );
    }
  }
  .infoContent {
    width: 100%;
    max-width: rem(580);
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: rem(24);
    padding: rem(24);
    margin-top: rem(120);
    > h1 {
      font-size: rem(28);
      line-height: rem(36);
      font-weight: 600;
      text-transform: uppercase;
      @include small-desktop {
        font-size: rem(24);
      }
    }
    > h2 {
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(28);
      opacity: 0.7;
      @include small-desktop {
        font-size: rem(14);
      }
    }
    .genderBlock {
      display: flex;
      justify-content: center;
      gap: rem(20);
      animation: button-fade 400ms backwards;
      width: 100%;
      max-width: rem(400);
      margin: rem(18) auto 0;
      .genderButton {
        cursor: pointer;
        font-weight: 300;
        gap: rem(8);
        min-width: rem(144);
        padding: rem(14) rem(32);
        border-radius: rem(8);
        background-size: 238% auto;
        border: rem(1) solid c(main);
        display: flex;
        align-items: center;
        justify-content: center;
        transition:
          transform 400ms,
          border 400ms,
          background 400ms cubic-bezier(0.4, 0, 0.2, 1);
        @include hover {
          transform: scale(1.05);
        }
        @include small-desktop {
          max-width: initial;
          width: 100%;
        }
        > i {
          font-size: rem(24);
        }
        &.active {
          background-position: right;
        }
        &.male {
          background-image: linear-gradient(
            66deg,
            #{c(main, 0.8)} 0%,
            #{c(main, 0.08)} 45%,
            #{c(male, 0.4)} 48%,
            #{c(male, 0.02)} 100%
          );
          &.active {
            border-color: c(male, 0.1);
          }
        }
        &.female {
          background-image: linear-gradient(
            66deg,
            #{c(main, 0.8)} 0%,
            #{c(main, 0.08)} 45%,
            #{c(female, 0.4)} 48%,
            #{c(female, 0.02)} 100%
          );
          &.active {
            border-color: c(female, 0.1);
          }
        }
      }
    }
    .logoBlock {
      position: relative;
      z-index: 1;
      font-size: rem(170);
      display: flex;
      justify-content: center;
      align-items: center;
      @include small-desktop {
        font-size: rem(150);
      }
      > i {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(
          190deg,
          c(text, 0.4) 12.92%,
          c(text, 0) 96.32%
        );
      }
      > img {
        position: absolute;
        width: rem(300);
        z-index: 1;
        mix-blend-mode: plus-lighter;
        filter: blur(6px);
        animation: spin-colors 25s linear infinite;
        @include small-desktop {
          width: rem(240);
        }
      }
    }
  }
  @keyframes spin-colors {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
