@import "src/assets/scss/_core.scss";

.moneyBackPolicyPage {
  width: 100%;
  max-width: rem(800);
  margin: 0 auto;
  padding: rem(24) rem(24) rem(40);
  animation: page-fade 1.2s backwards ease-in-out;
  height: 100vh;
  overflow-y: auto;
  position: relative;

  .backButton {
    position: sticky;
    top: rem(24);
    left: 0;
    display: inline-flex;
    align-items: center;
    gap: rem(8);
    padding: rem(12) rem(20);
    background: c(gradient);
    border: rem(1) solid c(main);
    border-radius: rem(8);
    cursor: pointer;
    margin-bottom: rem(16);
    transition: all 0.2s ease;
    font-weight: 500;
    z-index: 10;

    i {
      font-size: rem(20);
    }

    &:hover {
      background: c(main, 0.8);
    }
  }

  .content {
    background: c(gradient);
    border: rem(1) solid c(main);
    border-radius: rem(8);
    padding: rem(24) rem(24);

    .lastUpdated {
      color: c(text, 0.6);
      font-size: rem(14);
      margin-bottom: rem(16);
    }

    h1 {
      font-size: rem(24);
      font-weight: 600;
      margin-bottom: rem(24);
      color: c(text);
    }

    section {
      margin-bottom: rem(24);

      &:last-child {
        margin-bottom: 0;
      }

      h2 {
        font-size: rem(20);
        font-weight: 600;
        margin-bottom: rem(12);
        color: c(text);
      }

      p {
        font-size: rem(16);
        line-height: 1.6;
        color: c(text, 0.8);
        margin-bottom: rem(12);

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: c(text);
          text-decoration: underline;
        }
      }

      ul {
        margin: rem(12) 0;
        padding-left: rem(24);

        li {
          font-size: rem(16);
          line-height: 1.6;
          color: c(text, 0.8);
          margin-bottom: rem(8);
          list-style-type: disc;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .notice {
        font-style: italic;
        padding: rem(12);
        background: c(main, 0.3);
        border-radius: rem(8);
        margin-top: rem(16);
      }

      .stateNotices {
        margin-top: rem(16);
        
        p {
          padding: rem(12);
          background: c(main, 0.3);
          border-radius: rem(8);
          margin-bottom: rem(12);

          strong {
            color: c(text);
            display: block;
            margin-bottom: rem(8);
          }
        }
      }
    }
  }
} 