@import "src/assets/scss/_core.scss";

.policyBlock {
  display: flex;
  gap: rem(16);
  align-items: center;
  padding: 0 rem(16);
  border: rem(1) dashed transparent;
  border-radius: rem(6);
  transition: all 300ms ease-in-out;
  position: relative;
  margin-top: rem(50);
  margin-bottom: rem(8);
  cursor: pointer;
  .warningInfo {
    top: rem(-28);
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    color: c(secondary);
    opacity: 0;
    transition: all 300ms;
  }
  &.warning {
    padding: rem(16) rem(16);
    border-color: c(secondary);
    .warningInfo {
      opacity: 1;
    }
  }
  .info {
    p {
      font-weight: 300;
      font-size: rem(12);
      line-height: rem(18);
      color: c(text, 0.8);
      a {
        color: c(text);
        text-decoration: underline;
      }
    }
  }
}
