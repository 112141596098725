@import "src/assets/scss/_core.scss";

.inputWrapper {
  > input {
    width: 100%;
    font-size: rem(26);
    font-weight: 600;
    height: rem(76);
    padding: rem(10) rem(12);
    text-align: center;
    border-radius: rem(16);
    border: rem(1) solid c(main);
    transition: all 400ms;
    &.isSmall {
      height: rem(60);
      font-size: rem(24);
    }
    &:focus {
      border: rem(1) solid c(primary);
    }
    &.error {
      border: rem(1) solid c(danger);
    }
  }
  .errorText {
    color: c(danger);
    font-size: rem(14);
    margin-top: rem(8);
    padding: 0 rem(12);
    max-height: rem(35);
    transition: all 400ms;
    opacity: 1;
    overflow: hidden;
    &:empty {
      opacity: 0;
      max-height: 0;
      margin-top: 0;
    }
  }
}
