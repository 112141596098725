@import "src/assets/scss/_core.scss";

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: page-fade 1.2s backwards ease-in-out;
}
.formSwiper {
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  .questionContent {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    gap: rem(8);
    padding: 0 rem(24) calc(env(safe-area-inset-bottom) + #{rem(16)});
    max-width: rem(480);
    margin: 0 auto;
    &.goal-1 {
      .imageBlock {
        transform: none;
      }
    }
    &.equipment {
      .sectionTitle {
        font-weight: 600;
        font-size: rem(18);
        margin: rem(20) 0 rem(10);
      }
    }
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    .optionBlock {
      flex: 1;
      margin-top: rem(16);
      &.column {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(8);
        margin-bottom: auto;
        flex: 0;
        .singleOption {
          margin: 0 !important;
          height: rem(90);
          min-height: auto;
          padding: rem(10) rem(12) rem(2);
          .label {
            font-size: rem(16);
            max-width: 55% !important;
          }
          .imageBlock {
            right: rem(-16);
            transform: scale(1.1);
          }
        }
      }
    }
    > .title {
      font-size: rem(24);
      font-weight: 700;
      line-height: rem(36);
    }
    > .subtitle {
      font-size: rem(18);
      font-weight: 700;
      line-height: rem(28);
    }
    > .description {
      font-size: rem(14);
      line-height: rem(22);
      opacity: 0.7;
      white-space: pre-line;
    }
    > .actionBlock {
      position: sticky;
      bottom: 0;
    }
  }
}
