@import "src/assets/scss/_core.scss";

.compProgressWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
  min-height: rem(54);
  margin-top: rem(18);
  flex-direction: column;
  padding: rem(20) 0;
  > .title {
    margin-bottom: rem(10);
    font-size: rem(14);
  }
  .progressBlock {
    height: rem(26);
    background: c(main, 0.5);
    border: rem(1) solid c(text, 0.1);
    border-radius: rem(20);
    width: 70%;
    position: relative;
    overflow: hidden;
    padding: rem(2);
    > p {
      font-size: rem(12);
      position: absolute;
      width: 100%;
      height: rem(20);
      display: flex;
      justify-content: center;
      align-items: center;
      color: c(text);
    }
    @include small-desktop {
      width: 80%;
    }
    .line {
      top: 0;
      left: 0;
      background: c(text);
      height: 100%;
      border-radius: rem(25);
      transition: width 400ms;
      min-width: rem(20);
      max-width: 100%;
      overflow: hidden;
      position: relative;
      > p {
        font-size: rem(12);
        position: absolute;
        height: rem(20);
        display: flex;
        justify-content: center;
        align-items: center;
        color: c(main);
      }
    }
  }
}
